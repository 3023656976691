import {useMateBaseUrl} from "./base/useMateBaseUrl";

const mateRegex = /mate:\/\//;

export const useMate = () => {
    
    const ensureUrl = (url: string) => {
        const {baseUrl} = useMateBaseUrl();
        return url.replace(mateRegex, baseUrl);
    }

    const isMateUrl = (url: string) => 
        mateRegex.test(url)

    return {
        ensureUrl,
        isMateUrl
    }

}